import { template as template_386f67b95de74b57b7dab8424e88407f } from "@ember/template-compiler";
const FKControlMenuContainer = template_386f67b95de74b57b7dab8424e88407f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
