import { template as template_d1681f1898f44f1db63f758e5d9c5dc4 } from "@ember/template-compiler";
const FKLabel = template_d1681f1898f44f1db63f758e5d9c5dc4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
