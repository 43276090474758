import { template as template_2ebff416eb484b36b0cbe0113d887566 } from "@ember/template-compiler";
const WelcomeHeader = template_2ebff416eb484b36b0cbe0113d887566(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
